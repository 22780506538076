import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
const firebaseConfig = {
	apiKey: 'AIzaSyB2MOsNyjIEgG7MVVxa8HpbHGDdeOatjbk',
	authDomain: 'tabor-bnb.firebaseapp.com',
	projectId: 'tabor-bnb',
	storageBucket: 'gs://tabor-bnb.appspot.com',
	messagingSenderId: '903158896013',
	appId: '1:903158896013:web:799e26adf3df6c95ed99c4',
	measurementId: 'G-9WKFTEQ2H6',
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

let LR1 = { url: '', description: 'Open layout kitchen + dining & living space' };
const LR1ref = ref(storage, 'images_full_size/living_room_1.jpg');
getDownloadURL(LR1ref).then((url) => {
	LR1.url = url;
});
let LR2 = { url: '', description: 'Bright, open layout living and dining space | arcade & smart TV' };
const LR2ref = ref(storage, 'images_full_size/living_room_2.jpg');
getDownloadURL(LR2ref).then((url) => {
	LR2.url = url;
});
let LR3 = { url: '', description: 'Dining space | modern boho decor' };
const LR3ref = ref(storage, 'images_full_size/living_room_3.jpg');
getDownloadURL(LR3ref).then((url) => {
	LR3.url = url;
});
let LR4 = { url: '', description: 'Comfortable living room & arcade for entertainment' };
const LR4ref = ref(storage, 'images_full_size/living_room_4.jpg');
getDownloadURL(LR4ref).then((url) => {
	LR4.url = url;
});
let LR5 = { url: '', description: 'Class of 1981 Ms. Pacman & Galaga arcade | fun for the whole family!' };
const LR5ref = ref(storage, 'images_full_size/living_room_5.jpg');
getDownloadURL(LR5ref).then((url) => {
	LR5.url = url;
});
let LR6 = { url: '', description: 'Lively, homey living space w/ comfortable sectional' };
const LR6ref = ref(storage, 'images_full_size/living_room_6.jpg');
getDownloadURL(LR6ref).then((url) => {
	LR6.url = url;
});
let LR7 = { url: '', description: 'Family Dining Table' };
const LR7ref = ref(storage, 'images_full_size/living_room_7.jpg');
getDownloadURL(LR7ref).then((url) => {
	LR7.url = url;
});
let K1 = {
	url: '',
	description:
		"Large kitchen space | perfect for entertaining if you decide to take a break from Portland's food scene",
};
const K1ref = ref(storage, 'images_full_size/kitchen_1.jpg');
getDownloadURL(K1ref).then((url) => {
	K1.url = url;
});
let K2 = { url: '', description: 'Open layout kitchen' };
const K2ref = ref(storage, 'images_full_size/kitchen_2.jpg');
getDownloadURL(K2ref).then((url) => {
	K2.url = url;
});
let BDR1 = { url: '', description: 'Downstairs primary bedroom | king size bed' };
const BDR1ref = ref(storage, 'images_full_size/bedroom_1.jpg');
getDownloadURL(BDR1ref).then((url) => {
	BDR1.url = url;
});
let BDR2 = { url: '', description: 'Downstairs primary bedroom & bathroom' };
const BDR2ref = ref(storage, 'images_full_size/bedroom_2.jpg');
getDownloadURL(BDR2ref).then((url) => {
	BDR2.url = url;
});
let BDR3 = { url: '', description: 'Upstairs bedroom | 2 twin size beds' };
const BDR3ref = ref(storage, 'images_full_size/bedroom_3.jpg');
getDownloadURL(BDR3ref).then((url) => {
	BDR3.url = url;
});
let BDR4 = { url: '', description: 'Vibrant upstairs bedroom | queen size bed' };
const BDR4ref = ref(storage, 'images_full_size/bedroom_4.jpg');
getDownloadURL(BDR4ref).then((url) => {
	BDR4.url = url;
});
let BDR5 = { url: '', description: 'Upstairs bedroom | queen size bed' };
const BDR5ref = ref(storage, 'images_full_size/bedroom_5.jpg');
getDownloadURL(BDR5ref).then((url) => {
	BDR5.url = url;
});
let BDR6 = { url: '', description: 'Bright, modern upstairs bedroom' };
const BDR6ref = ref(storage, 'images_full_size/bedroom_6.jpg');
getDownloadURL(BDR6ref).then((url) => {
	BDR6.url = url;
});
let BDR7 = { url: '', description: 'Upstairs primary bedroom | 2 queen size beds' };
const BDR7ref = ref(storage, 'images_full_size/bedroom_7.jpg');
getDownloadURL(BDR7ref).then((url) => {
	BDR7.url = url;
});
let BDR8 = { url: '', description: 'Upstairs primary bedroom | 2 queen beds' };
const BDR8ref = ref(storage, 'images_full_size/bedroom_8.jpg');
getDownloadURL(BDR8ref).then((url) => {
	BDR8.url = url;
});
let BDR9 = { url: '', description: 'Upstairs primary bedroom | primary bathroom' };
const BDR9ref = ref(storage, 'images_full_size/bedroom_9.jpg');
getDownloadURL(BDR9ref).then((url) => {
	BDR9.url = url;
});
let BTH1 = { url: '', description: 'Downstairs primary bathroom' };
const BTH1ref = ref(storage, 'images_full_size/bathroom_1.jpg');
getDownloadURL(BTH1ref).then((url) => {
	BTH1.url = url;
});
let BTH2 = { url: '', description: 'Downstairs primary bathroom | walk-in closet' };
const BTH2ref = ref(storage, 'images_full_size/bathroom_2.jpg');
getDownloadURL(BTH2ref).then((url) => {
	BTH2.url = url;
});
let BTH3 = { url: '', description: 'Upstairs primary bathroom' };
const BTH3ref = ref(storage, 'images_full_size/bathroom_3.jpg');
getDownloadURL(BTH3ref).then((url) => {
	BTH3.url = url;
});
let BTH4 = { url: '', description: 'Upstairs primary bathroom w/ walk-in closet' };
const BTH4ref = ref(storage, 'images_full_size/bathroom_4.jpg');
getDownloadURL(BTH4ref).then((url) => {
	BTH4.url = url;
});
let BTH5 = { url: '', description: 'Upstairs bathroom' };
const BTH5ref = ref(storage, 'images_full_size/bathroom_5.jpg');
getDownloadURL(BTH5ref).then((url) => {
	BTH5.url = url;
});
let BTH6 = { url: '', description: 'Private upstairs bathroom | separated shower and sink areas' };
const BTH6ref = ref(storage, 'images_full_size/bathroom_6.jpg');
getDownloadURL(BTH6ref).then((url) => {
	BTH6.url = url;
});
let BTH7 = { url: '', description: 'Half bathroom off of living room' };
const BTH7ref = ref(storage, 'images_full_size/bathroom_7.jpg');
getDownloadURL(BTH7ref).then((url) => {
	BTH7.url = url;
});
let LND = { url: '', description: 'Laundry room | available upon request' };
const LNDref = ref(storage, 'images_full_size/laundry.jpg');
getDownloadURL(LNDref).then((url) => {
	LND.url = url;
});
let EXT = { url: '', description: '' };
const EXTref = ref(storage, 'images_full_size/exterior.jpg');
getDownloadURL(EXTref).then((url) => {
	EXT.url = url;
});

// ---------------------------------------SMALL SIZE FOR MOBILE------------------------------------------->

let LR1sm = { url: '', description: 'Open layout kitchen + dining & living space' };
const LR1smref = ref(storage, 'images_small/living_room_1_small.jpg');
getDownloadURL(LR1smref).then((url) => {
	LR1sm.url = url;
});
let LR2sm = { url: '', description: 'Bright, open layout living and dining space | arcade & smart TV' };
const LR2smref = ref(storage, 'images_small/living_room_2_small.jpg');
getDownloadURL(LR2smref).then((url) => {
	LR2sm.url = url;
});
let LR3sm = { url: '', description: 'Dining space | modern boho decor' };
const LR3smref = ref(storage, 'images_small/living_room_3_small.jpg');
getDownloadURL(LR3smref).then((url) => {
	LR3sm.url = url;
});
let LR4sm = { url: '', description: 'Comfortable living room & arcade for entertainment' };
const LR4smref = ref(storage, 'images_small/living_room_4_small.jpg');
getDownloadURL(LR4smref).then((url) => {
	LR4sm.url = url;
});
let LR5sm = { url: '', description: 'Class of 1981 Ms. Pacman & Galaga arcade | fun for the whole family!' };
const LR5smref = ref(storage, 'images_small/living_room_5_small.jpg');
getDownloadURL(LR5smref).then((url) => {
	LR5sm.url = url;
});
let LR6sm = { url: '', description: 'Lively, homey living space w/ comfortable sectional' };
const LR6smref = ref(storage, 'images_small/living_room_6_small.jpg');
getDownloadURL(LR6smref).then((url) => {
	LR6sm.url = url;
});
let LR7sm = { url: '', description: 'Family Dining Table' };
const LR7smref = ref(storage, 'images_small/living_room_7_small.jpg');
getDownloadURL(LR7smref).then((url) => {
	LR7sm.url = url;
});
let K1sm = {
	url: '',
	description:
		"Large kitchen space | perfect for entertaining if you decide to take a break from Portland's food scene",
};
const K1smref = ref(storage, 'images_small/kitchen_1_small.jpg');
getDownloadURL(K1smref).then((url) => {
	K1sm.url = url;
});
let K2sm = { url: '', description: 'Open layout kitchen' };
const K2smref = ref(storage, 'images_small/kitchen_2_small.jpg');
getDownloadURL(K2smref).then((url) => {
	K2sm.url = url;
});
let BDR1sm = { url: '', description: 'Downstairs primary bedroom | king size bed' };
const BDR1smref = ref(storage, 'images_small/bedroom_1_small.jpg');
getDownloadURL(BDR1smref).then((url) => {
	BDR1sm.url = url;
});
let BDR2sm = { url: '', description: 'Downstairs primary bedroom & bathroom' };
const BDR2smref = ref(storage, 'images_small/bedroom_2_small.jpg');
getDownloadURL(BDR2smref).then((url) => {
	BDR2sm.url = url;
});
let BDR3sm = { url: '', description: 'Upstairs bedroom | 2 twin size beds' };
const BDR3smref = ref(storage, 'images_small/bedroom_3_small.jpg');
getDownloadURL(BDR3smref).then((url) => {
	BDR3sm.url = url;
});
let BDR4sm = { url: '', description: 'Vibrant upstairs bedroom | queen size bed' };
const BDR4smref = ref(storage, 'images_small/bedroom_4_small.jpg');
getDownloadURL(BDR4smref).then((url) => {
	BDR4sm.url = url;
});
let BDR5sm = { url: '', description: 'Upstairs bedroom | queen size bed' };
const BDR5smref = ref(storage, 'images_small/bedroom_5_small.jpg');
getDownloadURL(BDR5smref).then((url) => {
	BDR5sm.url = url;
});
let BDR6sm = { url: '', description: 'Bright, modern upstairs bedroom' };
const BDR6smref = ref(storage, 'images_small/bedroom_6_small.jpg');
getDownloadURL(BDR6smref).then((url) => {
	BDR6sm.url = url;
});
let BDR7sm = { url: '', description: 'Upstairs primary bedroom | 2 queen size beds' };
const BDR7smref = ref(storage, 'images_small/bedroom_7_small.jpg');
getDownloadURL(BDR7smref).then((url) => {
	BDR7sm.url = url;
});
let BDR8sm = { url: '', description: 'Upstairs primary bedroom | 2 queen beds' };
const BDR8smref = ref(storage, 'images_small/bedroom_8_small.jpg');
getDownloadURL(BDR8smref).then((url) => {
	BDR8sm.url = url;
});
let BDR9sm = { url: '', description: 'Upstairs primary bedroom | primary bathroom' };
const BDR9smref = ref(storage, 'images_small/bedroom_9_small.jpg');
getDownloadURL(BDR9smref).then((url) => {
	BDR9sm.url = url;
});
let BTH1sm = { url: '', description: 'Downstairs primary bathroom' };
const BTH1smref = ref(storage, 'images_small/bathroom_1_small.jpg');
getDownloadURL(BTH1smref).then((url) => {
	BTH1sm.url = url;
});
let BTH2sm = { url: '', description: 'Downstairs primary bathroom | walk-in closet' };
const BTH2smref = ref(storage, 'images_small/bathroom_2_small.jpg');
getDownloadURL(BTH2smref).then((url) => {
	BTH2sm.url = url;
});
let BTH3sm = { url: '', description: 'Upstairs primary bathroom' };
const BTH3smref = ref(storage, 'images_small/bathroom_3_small.jpg');
getDownloadURL(BTH3smref).then((url) => {
	BTH3sm.url = url;
});
let BTH4sm = { url: '', description: 'Upstairs primary bathroom w/ walk-in closet' };
const BTH4smref = ref(storage, 'images_small/bathroom_4_small.jpg');
getDownloadURL(BTH4smref).then((url) => {
	BTH4sm.url = url;
});
let BTH5sm = { url: '', description: 'Upstairs bathroom' };
const BTH5smref = ref(storage, 'images_small/bathroom_5_small.jpg');
getDownloadURL(BTH5smref).then((url) => {
	BTH5sm.url = url;
});
let BTH6sm = { url: '', description: 'Private upstairs bathroom | separated shower and sink areas' };
const BTH6smref = ref(storage, 'images_small/bathroom_6_small.jpg');
getDownloadURL(BTH6smref).then((url) => {
	BTH6sm.url = url;
});
let BTH7sm = { url: '', description: 'Half bathroom off of living room' };
const BTH7smref = ref(storage, 'images_small/bathroom_7_small.jpg');
getDownloadURL(BTH7smref).then((url) => {
	BTH7sm.url = url;
});
let LNDsm = { url: '', description: 'Laundry room | available upon request' };
const LNDsmref = ref(storage, 'images_small/laundry_small.jpg');
getDownloadURL(LNDsmref).then((url) => {
	LNDsm.url = url;
});

export {
	LR1,
	LR2,
	LR3,
	LR4,
	LR5,
	LR6,
	LR7,
	K1,
	K2,
	BDR1,
	BDR2,
	BDR3,
	BDR4,
	BDR5,
	BDR6,
	BDR7,
	BDR8,
	BDR9,
	BTH1,
	BTH2,
	BTH3,
	BTH4,
	BTH5,
	BTH6,
	BTH7,
	LND,
	EXT,
	LR1sm,
	LR2sm,
	LR3sm,
	LR4sm,
	LR5sm,
	LR6sm,
	LR7sm,
	K1sm,
	K2sm,
	BDR1sm,
	BDR2sm,
	BDR3sm,
	BDR4sm,
	BDR5sm,
	BDR6sm,
	BDR7sm,
	BDR8sm,
	BDR9sm,
	BTH1sm,
	BTH2sm,
	BTH3sm,
	BTH4sm,
	BTH5sm,
	BTH6sm,
	BTH7sm,
	LNDsm,
};
